import React from 'react';

const Copyright = ({className}) => {
    return (
        <p className={className ? className : ''}>
            © {new Date().getFullYear()} Light Speed International. Made with <i className="fa fa-heart text-danger" /> by <a className="text-inherit" href="//technoagile.io" target="_blank" rel="noopener noreferrer">Light Speed International.</a>
        </p>
    );
};

export default Copyright;
